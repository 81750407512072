import React from 'react';

import { UnderlinedHeadline } from '../global';
import { PriceCalculatorForm } from '../form';

const PriceCalculator = () => (
  <section className="bg-champagne">
    <div className="container">
      <UnderlinedHeadline>
        Kalkuliere den Preis für Dein Event
      </UnderlinedHeadline>
      <PriceCalculatorForm />
    </div>
  </section>
);

export default PriceCalculator;
