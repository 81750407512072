import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Button } from '../global';

const Intro = () => (
  <section className="isolate relative flex justify-center items-center min-h-screen bg-royal-blue-light text-black overflow-hidden">
    {/* Image */}
    <StaticImage
      src="../../assets/images/intro.jpg"
      alt=""
      layout="fullWidth"
      className="inset-0 filter blur-[5px]"
      style={{ position: 'absolute' }}
    />
    {/* Overlay */}
    <div className="absolute inset-0 bg-royal-blue-light bg-opacity-70" />
    {/* Content */}
    <div className="z-1 text-center px-4 space-y-16">
      <h1>
        Plane mit uns
        <br />
        Dein ganz individuelles Event!
      </h1>
      <p className="md:text-xl">
        Räume mieten inklusive Ausstattung &amp; Catering
      </p>
      <Button to="/kontakt">Plane dein Event</Button>
    </div>
  </section>
);

export default Intro;
