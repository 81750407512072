import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const itemClasses = 'grid gap-10 md:gap-16';

const LocationDetails = () => (
  <section>
    <div className="container">
      <div className="grid gap-12 md:gap-20 md:grid-cols-2 lg:gap-32">
        <div className={itemClasses}>
          <StaticImage
            src="../../assets/images/location-details1.jpg"
            alt=""
            layout="constrained"
            width={600}
            className="white-overlay"
          />
          <p>
            Unsere Bühne im Eventspace bietet ausreichend Platz für deinen
            individuellen Vortag. Mit der vorhandenen Traverse und eingebauter
            Lichttechnik kannst du den Fokus auf den Speaker lenken. Umgeben von
            zwei Leinwänden mit Beamer haben die Teilnehmer die Möglichkeit den
            Vortag voll und ganz zu folgen.
          </p>
        </div>
        <div className={`${itemClasses} pt-12`}>
          <StaticImage
            src="../../assets/images/location-details2.jpg"
            alt=""
            layout="constrained"
            width={600}
            className="white-overlay md:order-last"
          />
          <p className="md:ml-12">
            Die großzügige Fläche ermöglicht eine individuelle Gestaltung der
            Bestuhlung. Ob Reihenbestuhlung, Sitzkreis oder in Gruppen ist alles
            denkbar. Auch während deiner geplanten Veranstaltung kannst du agil
            deinen Raum passend gestalten.
          </p>
        </div>
      </div>
      <div
        className={`${itemClasses} items-center mt-12 md:mt-20 md:grid-cols-2 lg:mt-32 lg:mx-24`}
      >
        <StaticImage
          src="../../assets/images/location-details3.jpg"
          alt=""
          layout="constrained"
          width={600}
          className="white-overlay"
        />
        <p>
          Stay hydrated! Während der Nutzung des EventSpace steht euch unser
          Getränkekühlschrank unbegrenzt zur Verfügung. Wir haben sie alle - ob
          Cola, Apfelschorle, Wasser, Orangen- oder Rhabarber-Limo, Du kannst
          alle Sorten durchprobieren.
        </p>
      </div>
    </div>
  </section>
);

export default LocationDetails;
