import React from 'react';

import { UnderlinedHeadline } from '../global';

import { default as content } from '../../content/equipment';

const Equipment = () => (
  <section className="bg-champagne">
    <div className="container">
      <UnderlinedHeadline>Ausstattung</UnderlinedHeadline>
      <ul
        className="grid gap-12 mt-24"
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))'
        }}
      >
        {content.map(({ icon: Icon, name }, index) => (
          <li key={index} className="text-center">
            <Icon className="block w-16 h-16 text-royal-blue-medium mx-auto mb-8" />
            {name}
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export default Equipment;
