import React from 'react';

import { Title } from '../components/global';
import {
  Intro,
  Features,
  Equipment,
  LocationDetails,
  Kitchen,
  PriceCalculator,
  Contact
} from '../components/sections';

const IndexPage = () => (
  <>
    <Title prefix="Home" />
    <Intro />
    <Features />
    <Equipment />
    <LocationDetails />
    <Kitchen />
    <PriceCalculator />
    <Contact />
  </>
);

export default IndexPage;
