import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { CheckedList, UnderlinedHeadline } from '../global';

const listItems = [
  'Modern ausgestattet',
  '110 m² Fläche – flexibel und dynamisch',
  'Für Workshops, Seminare, Schulungen & Meetings',
  'Individuelle Bestuhlung passend zu Deinem Event',
  'Bereitstellung benötigter Materialien'
];

const Features = () => (
  <section>
    <div className="container space-y-28">
      <UnderlinedHeadline color="royal-blue-medium">
        Plane mit uns Dein ganz individuelles Event!
      </UnderlinedHeadline>
      <div className="grid gap-12 md:grid-cols-2">
        <StaticImage
          src="../../assets/images/features.jpg"
          alt=""
          layout="constrained"
          width={700}
          className="white-overlay md:order-last"
        />
        <CheckedList items={listItems} />
      </div>
      <p className="text-h3 text-center">
        Lass Dich von dem unkonventionellen Design in einer lockeren und
        kreativen Arbeitsatmosphäre leiten und beflügeln.
      </p>
    </div>
  </section>
);

export default Features;
