import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Button } from '../global';

const Contact = () => (
  <>
    <section className="bg-champagne-blue">
      <div className="text-center mb-24 space-y-16">
        <h2 className="text-h1">
          Kontaktiere unser Event-Team
          <br />
          und frage dein Event an.
        </h2>
        <Button to="/kontakt">Eventspace anfragen</Button>
      </div>
      <div className="container grid gap-12 md:grid-cols-2">
        <div className="grid gap-8 grid-cols-2">
          <p>Gerne sind wir auch telefonisch erreichbar unter:</p>
          <div className="flex items-end">
            <a
              href="tel:+4915161076196"
              className="font-semibold hover:text-royal-blue"
            >
              +49 (0)151 61076196
            </a>
          </div>
        </div>
        <div className="grid gap-8 grid-cols-2">
          <p>
            Unsere Eventlocation befindet sich in unserer Camp:Site:
          </p>
          <div className="flex items-end font-semibold uppercase">
            <ul>
              <li>Solgerstraße 18</li>
              <li>90429 Nürnberg</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="text-center">
      <StaticImage
        src="../../assets/images/crew.jpg"
        alt=""
        layout="constrained"
        aspectRatio={16 / 9}
        width={1000}
      />
    </section>
  </>
);

export default Contact;
