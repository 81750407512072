import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { CheckedList, UnderlinedHeadline } from '../global';

const listItems = [
  'Individuelles Catering',
  'Bewegliche Kochinsel',
  'Zusätzliche Offsite'
];

const Kitchen = () => (
  <section>
    <div className="container space-y-24">
      <UnderlinedHeadline color="black">Unsere Eventküche</UnderlinedHeadline>
      <div className="grid gap-12 md:grid-cols-3">
        <StaticImage
          src="../../assets/images/kitchen1.jpg"
          alt=""
          layout="constrained"
          width={700}
          className="white-overlay md:col-span-2"
        />
        <div className="space-y-8">
          <p>
            Unsere große Küche lädt ein, gemeinsam in den Tag zu starten.
            Während der Pause kannst Du Dich mit kühlen Softdrinks, warmen
            Getränken und leckeren Snacks stärken. Du kannst dort entspannen und
            Kraft tanken oder den Raum für lockere Gespräche nutzen.
            Ausgestattet mit einer beweglichen Insel ist unsere Event-Küche
            flexibel und perfekt für den Aufbau eines individuellen Caterings.
          </p>
          <p>
            Uns ist es wichtig, dass sich unsere Gäste wie zu Hause fühlen. Wir
            kümmern uns gerne um die Verpflegung während des Events, egal ob für
            einen Workshop, Schulung oder betriebliche Feier.
          </p>
        </div>
      </div>
      <div>
        <CheckedList items={listItems} parallel />
      </div>
    </div>
    <div className="container container--wide grid gap-6 mt-24 md:gap-10 md:grid-cols-3">
      <StaticImage
        src="../../assets/images/kitchen2.jpg"
        alt=""
        layout="constrained"
        aspectRatio={1}
        width={500}
        className="white-overlay"
      />
      <StaticImage
        src="../../assets/images/kitchen3.jpg"
        alt=""
        layout="constrained"
        aspectRatio={1}
        width={500}
        className="white-overlay"
      />
      <StaticImage
        src="../../assets/images/kitchen4.jpg"
        alt=""
        layout="constrained"
        aspectRatio={1}
        width={500}
        className="white-overlay"
      />
    </div>
  </section>
);

export default Kitchen;
