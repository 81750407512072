import FingerprintIcon from '../assets/icons/fingerprint.svg';
import ChairIcon from '../assets/icons/chair.svg';
import NachosIcon from '../assets/icons/nachos.svg';
import BottleIcon from '../assets/icons/bottle.svg';
import BrainIcon from '../assets/icons/brain.svg';
import AirConditioningIcon from '../assets/icons/air-conditioning.svg';
import SubwooferIcon from '../assets/icons/subwoofer.svg';
import VideoProjectorIcon from '../assets/icons/video-projector.svg';
import WifiIcon from '../assets/icons/wifi.svg';
import WhiteboardIcon from '../assets/icons/whiteboard.svg';
import MicrophoneIcon from '../assets/icons/microphone.svg';
import TreeIcon from '../assets/icons/tree.svg';

const equipment = [
  {
    icon: FingerprintIcon,
    name: 'Individuelle Betreuung'
  },
  {
    icon: ChairIcon,
    name: '100 Plätze'
  },
  {
    icon: NachosIcon,
    name: 'Snacks'
  },
  {
    icon: BottleIcon,
    name: 'Getränke'
  },
  {
    icon: BrainIcon,
    name: 'Kreative Umgebung'
  },
  {
    icon: AirConditioningIcon,
    name: 'Heizung & Klimaanlage'
  },
  {
    icon: SubwooferIcon,
    name: 'Soundanlage'
  },
  {
    icon: VideoProjectorIcon,
    name: 'Beamer'
  },
  {
    icon: WifiIcon,
    name: 'Wifi'
  },
  {
    icon: WhiteboardIcon,
    name: 'Flipcharts / Whiteboards'
  },
  {
    icon: MicrophoneIcon,
    name: 'Moderationskoffer'
  },
  {
    icon: TreeIcon,
    name: 'Außenbereich'
  }
];

export default equipment;
